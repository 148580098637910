import React, { useState, useEffect } from "react"
import "../styles/image-viewer.scss"
// markup
const ImageViewer = ({ images, projectName, projectIndex }) => {
    
  const [startPosition, setStartPosition] = useState(0);
  const [isHeldDown, setIsHeldDown] = useState(false);
  const [draggedDistance, setDraggedDistance] = useState(0);
  const [selectedImageViewer, setSelectedImageViewer] = useState(null);


  const handleOnMouseOver = (event) => 
  {
    setSelectedImageViewer(event.currentTarget);
  }


  const handleOnMouseDown = (event) => 
  {
    if(selectedImageViewer)
    {
        let currentClientX = event.touches ? event.touches[0].clientX : event.clientX;

        setStartPosition(currentClientX);
        setIsHeldDown(true);
        setStartPosition(selectedImageViewer.scrollLeft + currentClientX)
    }

  }

  const handleOnMouseUp = (event) => 
  {
    setIsHeldDown(false);
  }

  const handleOnMouseMove = (event) => 
  {
    if (isHeldDown)
    {
      let currentClientX = event.touches ? event.touches[0].clientX : event.clientX;
      setDraggedDistance(startPosition - currentClientX);
    }
  }

  const handleTouchStart = (event) => 
  {
    setSelectedImageViewer(event.currentTarget);
    handleOnMouseDown(event);

  }

  useEffect(() => 
  {
    if (selectedImageViewer) 
    {
        selectedImageViewer.scrollLeft = draggedDistance;
    }
  }, [draggedDistance])

    return (
        <button className="image-viewer"
            aria-hidden="true"

            // Mouse controls
            onMouseDown={handleOnMouseDown} 
            onMouseMove={handleOnMouseMove}
            onMouseUp={handleOnMouseUp}
            onMouseLeave={handleOnMouseUp}
            onMouseOver={handleOnMouseOver}

            // Touch controls
            onTouchStart={handleTouchStart}
            onTouchMove={handleOnMouseMove}

        >
            <div className="image-viewer__image-overlay" />
              {
                  images.map((image, index) => 
                      (
                          <img src={image} className="image-viewer__image" key={projectName+index} alt=""/>
                      )
                  )
              }
        </button>
    )
}

export default ImageViewer