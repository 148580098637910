import React from "react"

import '../styles/project.scss'

import Konrad1 from "../images/konrad/konrad1.png"
import Konrad2 from "../images/konrad/konrad2.png"
import Konrad3 from "../images/konrad/konrad3.png"
import Konrad4 from "../images/konrad/konrad4.png"
import Konrad5 from "../images/konrad/konrad5.png"

import je1 from "../images/jerkevert/Laptop.png"
import je2 from "../images/jerkevert/Laptop-1.png"
import je3 from "../images/jerkevert/Laptop-2.png"
import je4 from "../images/jerkevert/Laptop-3.png"
import je5 from "../images/jerkevert/Laptop-4.png"


import bf1 from "../images/bf/Browser.png"
import bf2 from "../images/bf/Browser-1.png"
import bf3 from "../images/bf/Browser-2.png"
import bf4 from "../images/bf/Browser-3.png"

import w1 from "../images/widgify/Browser.png"
import w2 from "../images/widgify/Browser-1.png"
import w3 from "../images/widgify/Browser-2.png"
import w4 from "../images/widgify/Browser-3.png"
import w5 from "../images/widgify/Browser-4.png"

import cn1 from "../images/chatnip/Browser.png"
import cn2 from "../images/chatnip/Browser-1.png"
import cn3 from "../images/chatnip/Browser-2.png"
import cn4 from "../images/chatnip/Phone.png"

import bb1 from "../images/BattleBoids/1.jpg"
import bb2 from "../images/BattleBoids/2.jpg"
import bb3 from "../images/BattleBoids/3.jpg"
import bb4 from "../images/BattleBoids/4.jpg"
import bb5 from "../images/BattleBoids/5.jpg"

import pp1 from "../images/PedalPlan/1.jpg"
import pp2 from "../images/PedalPlan/2.jpg"
import pp3 from "../images/PedalPlan/3.jpg"
import pp4 from "../images/PedalPlan/4.jpg"
import pp5 from "../images/PedalPlan/5.jpg"

import fm1 from "../images/fmerch/1.jpg"

import ImageViewer from "../components/ImageViewer"

const Projects = ({projectsReference}) => 
{
  const projects = [
 {
      title: 'fmerch',
      subject: 'Web development', 
      images: [fm1],
      description: "E-commerce site currently in development" ,
      link: "https://fmerch.netlify.app" 
    }, 
    {
      title: 'BattleBoids',
      subject: 'Video game development', 
      images: [bb1,bb2,bb3,bb4,bb5],
      description: "Battleboids is a work in progress game built using the Unity game engine and is the main focus of a bachelor's thesis at Chalmers university of technology and the University of Gothenburg. The game revolves around boid simulation behaviour as described by Craig Reynolds in his 1987 paper, 'Flocks, Herds, and Schools: A Distributed Behavioral Model'. My main area of concern has been the level design, user interaction functionality, and UI design." ,
      link: "https://github.com/palmdrop/BattleBoids" 
    }, 
    {
      title: 'PedalPlan',
      subject: 'UI/UX Human-computer Interaction', 
      images: [pp1, pp2, pp3, pp4, pp5], 
      description: "We were tasked to solve a cycling related problem, so we looked into adventurous bicycling that requires a lot of packing and preparation. Our goal was to identify the needs of these adventure cyclists and create a prototype of an application that would ensure a safe and complete preparation phase." ,
      link: "https://www.figma.com/proto/n3QrLBFVpgb15qUUMnRJGz/Rapid-prototyping?node-id=558%3A1631&scaling=min-zoom&page-id=558%3A1610" 
    }, 
    {
      title: 'Konrad',
      subject: 'Software development', 
      images: [Konrad1, Konrad2, Konrad3, Konrad4, Konrad5],
      description: "Konrad is an application developed in a course about Agile development. We got tasked to create an application that would aid the UN's sustainable development goals. I worked on designing the application, creating functionality to enable users to make posts, and search functionality to filter by tags and more." ,
      link: "https://github.com/feldtsen/obtuse-dit257" 
    }, 
    {
      title: 'Jerk Evert',
      subject: 'Video game development', 
      images: [je1, je2, je3, je4, je5],
      description: "A topdown 2D game developed in a course about object-oriented programming projects and Agile development. In this project, I created a 2D renderer where I separated the rendering logic from the game logic, allowing us to scale the game to any resolution without worrying about the physics involved (they used logic based on static distances). Additionally, I took the time to implement user input controls, sound and more.", 
      link: "https://github.com/feldtsen/pointy-dit212"
    },
    {
      title: 'Widgify',
      subject: 'Web development', 
      images: [w1, w2, w3, w4, w5],
      description: "A homepage-friendly website that gives you easy access to helpful tools. Worked on the design using MaterialUI and login capabilities, to name a few.",
      link: "https://iammille.github.io/widgify/build/"
    },
    {
      title: 'Chatnip',
      subject: 'Web development', 
      images: [cn2, cn1, cn3, cn4],
      description: "A very simple chat client made to test Firebase and login functionality.", 
      link: "https://feldtsen.github.io/chatnip/"
    },
    {
      title: 'Bondelia',
      subject: 'Web development', 
      images: [bf1, bf2, bf3, bf4],
      description: 'A prototype for a local community where users could share activities, upcoming events and important documents.',
      link: "https://feldtsen.github.io/bondeliafeltet/"

    }
  ]

  let skills = ["Java", "C#", "C++", "JS", "Sass", "React", "Linux", "Git", "Maven", "Firebase", "Shell", "JavaFX", "Unity", "Haskell", "MaterialUI", "Figma"]

  return (
    <div className="project" ref={projectsReference}>

      {
        projects.map((project, index) =>
        (
          <div className="project__entry">
            <ImageViewer images={project.images} projectName={project.title} projectIndex={index}/>
            <div className="project__description">
              <h1 className="project__description--title">{project.title}</h1>
              <h2 className="project__description--subject">{project.subject}</h2>
              <p className="project__description--text">{project.description}</p>
              <a href={project.link}><button className="project__description--cta-button">Have a closer look</button></a>
            </div>
          </div>
        ))
      }

          <div className="project__skills-title">
              Technologies I use to develop my projects
          </div>

          <div className="project__skills">
            {
              skills.map((skill, index) =>
              (
                <div className="project__skills--entry" key={skill+index}>{skill}</div>
              ))
            }
          </div>
          
    </div>

  )
}

export default Projects